<template>
  <div class="userInfo">
    <div class="userInfo_title">账号设置</div>
    <div class="userInfo_box">
      <div class="userInfo_head">
        <img :src="imageUrl" alt="" />
        <!-- <el-upload
          v-if="is_benren == 1"
          class="upload-demo"
          :limit="1"
          :headers="headers"
          :action="$api.url + '/api/' + $face.defaultUpload"
          :on-success="handleAvatarSuccess"
          :show-file-list="false"
          accept=".png, .jpg, .jpeg"
        >
           <el-button class="userInfo_handup">修改头像</el-button> 
        </el-upload> -->
        <button class="userInfo_handup" @click="dialogVisible = true"  v-if="is_benren == 1">
          修改头像
        </button>
        <div class="tips" v-if="is_benren == 1">（审核通过后展示）</div>

        <a class="logout" @click="logout" v-if="is_benren == 1">注销账号</a>
      </div>
      <div class="userInfo_content clearfix">
        <div class="userInfo_content_name">基本信息</div>
        <div class="user_info_hang">
          <div class="userInfo_item" v-if="is_benren != 1 && username">
            <div class="userInfo_headline">昵称</div>
            <div class="userInfo_user_other">{{ username }}</div>
          </div>
          <div class="userInfo_item" v-if="is_benren == 1">
            <div class="userInfo_headline">
              昵称<span class="tips">（审核通过后展示）</span>
            </div>
            <el-input
              v-model="username"
              placeholder="请输入内容"
              :disabled="is_benren == 0"
            ></el-input>
          </div>

          <div class="userInfo_item" v-if="is_benren != 1 && sex">
            <div class="userInfo_headline">性别</div>
            <div class="userInfo_user_other">
              {{ sex == 1 ? "男" : sex == 2 ? "女" : "保密" }}
            </div>
          </div>
          <div class="userInfo_item" v-if="is_benren == 1">
            <div class="userInfo_headline">性别</div>
            <el-select
              v-model="sex"
              placeholder="请选择"
              :disabled="is_benren == 0"
            >
              <el-option label="男" :value="1"></el-option>
              <el-option label="女" :value="2"></el-option>
              <el-option label="保密" :value="3"></el-option>
            </el-select>
          </div>
        </div>
        <div class="user_info_hang">
          <div class="userInfo_item" v-if="is_benren != 1 && autograph">
            <div class="userInfo_headline">职业</div>
            <div class="userInfo_user_other">{{ autograph }}</div>
          </div>
          <div class="userInfo_item" v-if="is_benren == 1">
            <div class="userInfo_headline">职业</div>
            <el-input
              v-model="autograph"
              placeholder="请输入内容"
              :disabled="is_benren == 0"
            ></el-input>
          </div>
          <div class="userInfo_item" v-if="is_benren != 1 && city">
            <div class="userInfo_headline">现居</div>
            <div class="userInfo_user_other">{{ city }}</div>
          </div>
          <div class="userInfo_item" v-if="is_benren == 1">
            <div class="userInfo_headline">现居</div>
            <el-select
              v-model="city"
              filterable
              placeholder="请选择"
              :disabled="is_benren == 0"
            >
              <el-option
                v-for="item in cityList"
                :key="item.id"
                :label="item.region_name"
                :value="item.region_name"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="userInfo_item" v-if="is_benren != 1 && describe">
          <div class="userInfo_headline">签名</div>
          <div class="userInfo_user_other">{{ describe }}</div>
        </div>
        <div class="userInfo_item" v-if="is_benren == 1">
          <div class="userInfo_headline">
            签名<span class="tips">（审核通过后展示）</span>
          </div>
          <el-input
            v-model="describe"
            :class="isRed ? 'more_length' : ''"
            placeholder="不超过20个汉字或40个字符"
            @input="timeEffectInput"
            :disabled="is_benren == 0"
          ></el-input>
        </div>
        <div class="userInfo_save" v-if="is_benren == 1" @click="saveEditbasic">
          保存
        </div>
      </div>
      <div class="userInfo_user clearfix" v-if="is_benren == 1">
        <div class="userInfo_user_name">个人信息</div>
        <div class="userInfo_user_list">
          <div class="userInfo_user_item" v-if="is_benren != 1 && mobile">
            <div class="userInfo_headline">手机</div>
            <div class="userInfo_user_other">{{ mobile }}</div>
          </div>
          <div class="userInfo_user_item" v-if="is_benren == 1">
            <div class="userInfo_headline">手机</div>
            <!-- <el-input v-model="mobile" placeholder="请输入内容" :disabled="is_benren==0"></el-input> -->
            <div class="userInfo_bind">
              <span>{{ mobile ? mobile : "未绑定" }}</span>
              <a @click="popstatus">去{{ mobile ? "换绑" : "绑定" }}>></a>
            </div>
          </div>
          <div class="userInfo_user_item" v-if="is_benren != 1 && email">
            <div class="userInfo_headline">邮箱</div>
            <div class="userInfo_user_other">{{ email }}</div>
          </div>
          <div class="userInfo_user_item" v-if="is_benren == 1">
            <div class="userInfo_headline">邮箱</div>
            <el-input
              v-model="email"
              placeholder="请输入内容"
              :disabled="is_benren == 0"
            ></el-input>
          </div>
          <!-- <div class="userInfo_user_item" v-if="is_benren != 1 && weixin">
						<div class="userInfo_headline">微信</div>
						<div class="userInfo_user_other">{{weixin}}</div>
					</div> -->
          <div class="userInfo_user_item" v-if="is_benren == 1">
            <div class="userInfo_headline">微信</div>
            <div class="userInfo_user_item_wx">
              <!-- <el-input v-model="weixin" placeholder="请输入内容" :disabled="is_benren==0"></el-input>
                            <div class="userInfo_user_item_bind" @click="bindFun" v-if="is_benren==1">绑定微信</div> -->
              <div class="userInfo_bind">
                <span>{{ weixin != "" ? "已绑定" : "未绑定" }}</span>
                <a @click="bindFun">去{{ weixin != "" ? "解绑" : "绑定" }}>></a>
              </div>
            </div>
          </div>
          <div class="userInfo_user_item" v-if="is_benren != 1 && qq">
            <div class="userInfo_headline">QQ</div>
            <div class="userInfo_user_other">{{ qq }}</div>
          </div>
          <div class="userInfo_user_item" v-if="is_benren == 1">
            <div class="userInfo_headline">QQ</div>
            <el-input
              v-model="qq"
              placeholder="请输入内容"
              :disabled="is_benren == 0"
            ></el-input>
          </div>
         <div class="userInfo_user_item" v-if="is_benren == 1">
           <div class="userInfo_headline">提现密码</div>
           <div class="userInfo_user_item_wx">
            <!-- <el-input v-model="weixin" placeholder="请输入内容" :disabled="is_benren==0"></el-input> -->
            <!-- <div class="userInfo_user_item_bind" @click="bindFun" v-if="is_benren==1">绑定微信</div> -->
             <div class="userInfo_bind">
               <span>{{ cash_passwords != "" ? "已设置" : "未设置" }}</span>
               <a @click="passwordFun">去{{ cash_passwords != "" ? "修改" : "设置" }}>></a>
             </div>
           </div>
         </div>
             <div class="userInfo_user_item" v-if="is_benren == 1">
            <div class="userInfo_headline">变形宝典（订单编号或激活码）</div>
            <div class="userInfo_user_item_wx">
              <div class="userInfo_bind">
                <span>{{ is_baodian != 0 ? bind_bd_code : "未绑定" }}</span>
                 <a @click="bindbxFun" v-if="is_baodian == 0">去绑定>></a>
              </div>
            </div>
          </div>
          <!-- <div class="userInfo_user_item" v-if="is_benren != 1 && birthday">
						<div class="userInfo_headline">生日</div>
						<div class="userInfo_user_other">{{birthday}}</div>
					</div> -->
          <!-- <div class="userInfo_user_item" v-if="is_benren==1">
						<div class="userInfo_headline">生日</div>
						<el-input v-model="birthday" placeholder="请输入内容" :disabled="is_benren==0"></el-input>
					</div>
					<div class="userInfo_user_item" v-if="is_benren != 1 && use">
						<div class="userInfo_headline">用途</div>
						<div class="userInfo_user_other">{{use==1?'个人':'企业'}}</div>
					</div> -->
          <!-- <div class="userInfo_user_item" v-if="is_benren==1">
						<div class="userInfo_headline">用途</div>
						<el-select v-model="use" placeholder="请选择" :disabled="is_benren==0">
							<el-option label="个人" :value="1"></el-option>
							<el-option label="企业" :value="2"></el-option>
						</el-select>
					</div> -->
        </div>

        <div
          class="userInfo_save userInfo_user_save"
          v-if="is_benren == 1"
          @click="saveUser"
        >
          保存
        </div>
      </div>
    </div>
    <div class="pop-up-bg" v-if="pop_up_bind_status == true">
      <div
        class="pop-up"
        :style="{ height: bindType == 0 ? '480px' : '350px' }"
      >
        <div class="pop-up-head">
          <h2>手机号</h2>
          <a @click="popstatus"><img src="../../assets/images/close.svg" /></a>
        </div>
        <div class="pop-up-content">
          <div class="smsCode_phone clearfix">
            <div>+86</div>
            <input type="text" v-model="newMobile" placeholder="请输入手机号" />
          </div>
          <div class="smsCode_code clearfix">
            <div class="fl smsCode_code_input">
              <input
                type="text"
                v-model="code"
                placeholder="请输入短信验证码"
              />
            </div>
            <div class="fr smsCode_code_b" @click="getCode()">
              {{ countDown ? Endtime : "获取验证码" }}
            </div>
          </div>
          <template v-if="bindType == 0">
            <div class="smsCode_phone password clearfix">
              <input
                type="text"
                placeholder="请输入6-30位密码"
                v-model="password"
                minlength="6"
                maxlength="30"
              />
            </div>
            <div class="smsCode_phone password clearfix">
              <input type="text" placeholder="确认密码" v-model="repassword" />
            </div>
          </template>
          <div class="btn clearfix">
            <button class="userInfo_save" @click="binds">绑定</button>
          </div>
        </div>
      </div>
    </div>
    <div class="background" v-show="alert">
      <div class="alert">
        <div class="">确认注销此账户吗？此操作不可撤销</div>
        <div class="alert_btn">
          <div class="alert_btn_cencel" @click="logout">取消</div>
          <div class="alert_btn_sure" @click="delAccFun">确定</div>
        </div>
      </div>
    </div>
    <div class="background" v-show="alert2">
      <div class="alert">
        <div class="">确认解绑微信吗？</div>
        <div class="alert_btn">
          <div class="alert_btn_cencel" @click="freeBind">取消</div>
          <div class="alert_btn_sure" @click="freeBindFun">确定</div>
        </div>
      </div>
    </div>
    <avatar-cropper
      :dialogVisible.sync="dialogVisible"
      @closeAvatarDialog="closeAvatarDialog"
    ></avatar-cropper>
   <binding v-if="popStatus" :type="1"></binding>

   <div class="bg" v-show="isCash"></div>
   <div class="cash_wrap" v-show="isCash">
        <div class="cashs">
            <div class="cash_title">提现密码</div>
            <div class="cash_box">
                <div v-if="!cash_passwords" class="cash_box_title">请设置密码</div>
                <!-- <input v-if="cash_passwords" class="cash_box_input" v-model="oldcash_password"
                    type="password" maxlength="8" placeholder="请输入旧密码" /> -->
                <input class="cash_box_input" v-model="cash_password"
                    type="password" maxlength="8" placeholder="请输入密码" />
                <input class="cash_box_input" v-model="recash_password"
                    type="password" maxlength="8" placeholder="请再次输入密码" />
                
                <input class="cash_box_input" type="text" v-model="mobile" placeholder="请输入手机号" disabled/>
                <div class="cashs_code clearfix">
                  <input
                      class="cash_box_input"
                      type="text"
                      v-model="cash_code"
                      placeholder="请输入短信验证码"
                    />
                  <div class="cashs_code_b" @click="getcashCode()">
                    {{ countDown ? Endtime : "获取验证码" }}
                  </div>
                </div>
                <div class="cash_password" @click="passwordSaveFun">确认密码</div>
            </div>

            <div class="close" @click="isCash = false">
                <img src="../../assets/images/dl_icon03@2x.svg" />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import avatarCropper from "../../components/avatarCropper";
import binding from "../../components/binding.vue";
export default {
  name: "userInfo",
  data() {
    return {
      dialogVisible: false,
      newMobile: "",
      code: "",
      password: "",
      repassword: "",
      username: "",
      sex: "", //性别
      describe: "", //介绍
      city: "", //现居
      autograph: "", //职业
      mobile: "",
      email: "",
      qq: "",
      birthday: "",
      weixin: "",
      cash_passwords: "",
      oldcash_password:'',
      cash_password:'',
      recash_password:'',
      isCash:false,
      use: "",
      imageUrl: "", //头像
      headers: {
        token: localStorage.getItem("access_token"),
      },
      cityList: [], //城市列表
      is_benren: 1,
      unionid: "",
      isRed: false,
      countDown: false,
      Endtime: 60,
      pop_up_bind_status: false,
      bindType: 0,
      alert: false,
      alert2: false,
      popStatus:false,
      is_baodian:0,
      bind_bd_code: '',

      cash_code: '',

      type:''
    };
  },
  components: {
    avatarCropper,
    binding
  },
  mounted() {
    this.$parent.tab = 2;
    this.$parent.tabIndex = 5;
    this.$parent.$parent.routerIndex = 0;
    this.$parent.$parent.showIndex = 1;
    this.is_benren = this.$parent.is_benren;
    this.type = this.$route.query.type;
    this.initFun();
    this.cityFun();
    //this.getUser();
  },
  methods: {
    initFun() {
      var that = this;
      this.$api.POST(
        this.$face.memberInfo,
        {
          user_id: this.$route.query.userId || "",
        },
        function (res) {
          var info = res.data;
          that.is_benren = res.data.is_benren;
          //localStorage.setItem('is_benren', res.data.is_benren);
          // if (res.data.is_benren != 1) {
          // 	localStorage.setItem('userInfo_other', JSON.stringify(res.data))
          // }
          that.username = info.username;

          if (info.thumb.indexOf("http") == -1) {
            that.imageUrl = require("../../assets/images/head.png");
          } else {
            that.imageUrl = info.thumb;
          }

          that.sex = info.sex;
          that.describe = info.describe;
          that.city = info.city;
          that.autograph = info.autograph;

          that.mobile = info.mobile;
          that.email = info.email;
          that.qq = info.qq;
          that.birthday = info.birthday;
          that.weixin = info.unionid;
          that.cash_passwords = info.cash_password;
          that.is_baodian = info.is_baodian;
          that.bind_bd_code = info.bind_bd_code;
          that.use = info.use;
          if (that.is_benren == 1) {
            that.unionid = info.unionid;
          }
          if (that.type == 'pw') {
            that.passwordFun()
            that.type = '';
          }
        }
      );
    },
    // 获取市
    cityFun() {
      var that = this;
      this.$api.POST(this.$face.font_regionCity, "", function (res) {
        that.cityList = res.data;
      });
    },
    handleAvatarSuccess(res, file) {
      this.$utile.prompt("success", "上传成功");
      this.imageUrl = res.initialPreview[0];
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      // this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      // this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      return isLt2M;
    },
    popstatus() {
      this.pop_up_bind_status = !this.pop_up_bind_status;
    },
    // 保存基本信息
    saveEditbasic() {
      var that = this;
      var params = {
        username: this.username,
        thumb: this.imageUrl,
        sex: this.sex, //性别
        describe: this.describe, //介绍
        city: this.city, //现居
        autograph: this.autograph, //职业
      };
      this.$api.POST(this.$face.memberEditbasic, params, function (res) {
        that.$utile.prompt2("success", "<div style='line-height:22px;padding-left:10px'>保存成功，24小时内审核通过<br>通过前显示原头像/昵称/签名</div>");
        that.getUser();
      });
    },
    // 保存个人信息
    saveUser() {
      var that = this;
      var params = {
        mobile: this.mobile,
        email: this.email,
        qq: this.qq,
        // birthday: this.birthday,
        // use: this.use,
      };
      this.$api.POST(this.$face.memberEditpersonal, params, function (res) {
        that.$utile.prompt("success", "保存成功");
        that.getUser();
      });
    },
    //获取用户信息
    getUser() {
      var that = this;
      this.$api.POST(this.$face.memberInfo, "", function (res) {
        that.$parent.userInfo = res.data;
        that.$parent.$parent.userInfo = res.data;
        if (res.data.is_benren == 1) {
            that.is_baodian = res.data.is_baodian;
            that.bind_bd_code = res.data.bind_bd_code;
            that.weixin = res.data.unionid;
            that.mobile = res.data.mobile;
            that.bindType=that.mobile == "" || that.mobile == null ? 0 : 1
        }
        localStorage.setItem("userInfo", JSON.stringify(res.data));
      });
    },
    binds() {
      let that = this;
      let params = {
        mobile: this.newMobile,
        code: this.code,
        password: this.password,
        repassword: this.repassword,
      };
      let url = this.$face.mobileBind;
      if (this.bindType == 0) {
        url = this.$face.mobileBindin;
      }
      this.$api.POST(url, params, function (res) {
        if (res.code == 200) {
          that.$utile.prompt("success", "保存成功");
          that.getUser();
          that.popstatus();
          that.newMobile = "";
          that.code = "";
        } else {
          that.$utile.prompt("error", res.msg);
        }
      });
    },
    bindbxFun(){
      if(this.is_baodian==1){
        return ;
      }
      this.popStatus=true
    },
    bindFun() {
      if (this.weixin == "") {
        var redirect_uri = encodeURIComponent(
          "https://www.zitisheji.com/#/userInfo"
        );
        window.location.href =
          "https://open.weixin.qq.com/connect/qrconnect?appid=wxa63eee7ec16a8c12&redirect_uri=" +
          redirect_uri +
          "&response_type=code&scope=snsapi_login&state=1#wechat_redirect";
      } else {
        this.alert2 = !this.alert2;
      }
    },
    freeBind() {
      this.alert2 = !this.alert2;
    },
    freeBindFun() {
      let that = this;
      if (this.weixin != "") {
        this.$api.POST(this.$face.freeMobileBindin, {}, function (res) {
          if (res.code == 200) {
            that.alert2 = false;
            that.weixin = "";
            that.$utile.prompt("success", "解绑成功");
          } else {
            that.$utile.prompt("error", res.msg);
          }
        });
      }
    },
    wxLoginFun() {
      var that = this;
      var params = {
        code: this.wxcode,
        my_id: JSON.parse(localStorage.getItem("userInfo")).id,
      }; 
      this.$api.POST(this.$face.authWechatopenredirect, params, function (res) {
        // localStorage.setItem("access_token", res.data.access_token);
        // localStorage.removeItem("userId");
        that.getUser();
        that.$router.push({
          name: "refresh",
          query: this.query,
        });
      });
    },

    //头像添加地址
    thumbFun() {
      if (this.imageUrl.indexOf("http") == -1) {
        this.imageUrl = require("../../assets/images/head.png");
      }
    },
    // 获取验证码
    getCode() {
      var that = this;
      var params = {
        mobile: this.newMobile,
      };
      this.$api.POST(this.$face.loginSms, params, function (res) {
        that.countdownFun();
        that.countDown = true;
      });
    },
    // 验证码倒计时
    countdownFun() {
      setTimeout(() => {
        this.Endtime--;
        if (this.Endtime == 0) {
          this.countDown = false;
          this.Endtime = 60;
        } else {
          this.countdownFun();
        }
      }, 1000);
    },
    // 个性签名限制
    timeEffectInput() {
      const length = this.describe.length; // 先拿到当前输入时文本域文字长度
      if (length > 20) {
        // 当长度超过200
        // 获取文本域计数统计设置字体为红色
        this.isRed = true;
        // 防止后续输入溢出截取0-200的字符
        this.describe = this.describe.substr(0, 20);
      } else {
        // 不超过则设置为原字体颜色
        this.isRed = false;
      }
    },
    //注销弹框
    logout() {
      this.alert = !this.alert;
    },

    delAccFun() {
      //this.$utile.prompt('error','测试');
      this.$api.POST(this.$face.logout, {}, (res) => {
        if (res.code == 200) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("userInfo");
          this.$store.commit("userInfo", "");
          this.$parent.userShow = false;
          this.$router.push({
            name: "/",
          });
        } else {
          this.$utile.prompt("error", res.msg);
        }
      });
    },
    closeAvatarDialog(data) {
      this.$api.POST('default/uploadfilesimg', { file_base: data }, (res) => {
        this.imageUrl = res.initialPreview[0];
        this.dialogVisible = false;
      });
    },
    // 提现密码
    passwordFun() {
      if (this.mobile) {
        this.isCash = true
      } else {
        this.pop_up_bind_status = true
      }
      
    },
    // 提现获取验证码
    getcashCode() {
      var that = this;
      var params = {
        mobile: this.mobile,
      };
      this.$api.POST(this.$face.loginSms, params, function (res) {
        that.countdownFun();
        that.countDown = true;
      });
    },
    passwordSaveFun() {
      var that = this;
      if (!this.cash_passwords) {
        if (!this.cash_password) {
            this.$utile.prompt("error", "请输入密码");
            return false
        }
        if (!this.recash_password) {
            this.$utile.prompt("error", "请再次输入密码");
            return false
        }
        if (this.cash_password!=this.recash_password) {
            this.$utile.prompt("error", "两次输入密码不一致");
            return false
        }
        if (!this.cash_code) {
            this.$utile.prompt("error", "请输入验证码");
            return false
        }
        
        this.$api.POST(
            this.$face.member_addcash,
            {
                cash_password: this.cash_password,
                recash_password: this.recash_password,
                mobile: this.mobile,
                code: this.cash_code,
            },
            function (res) {
                that.isCash = false;
              that.$utile.prompt("success", "密码设置成功");
              that.getUser();
            }
        );
      } else {
        // if (!this.oldcash_password) {
        //     this.$utile.prompt("error", "请输入旧密码");
        //     return false
        // }
        if (!this.cash_password) {
            this.$utile.prompt("error", "请输入密码");
            return false
        }
        if (!this.recash_password) {
            this.$utile.prompt("error", "请再次输入密码");
            return false
        }
        if (this.cash_password!=this.recash_password) {
            this.$utile.prompt("error", "两次输入密码不一致");
            return false
        }
        if (!this.cash_code) {
            this.$utile.prompt("error", "请输入验证码");
            return false
        }
        this.$api.POST(
            this.$face.member_editcash,
          {
              // oldcash_password:this.oldcash_password,
              newcash_password: this.cash_password,
              renewcash_password: this.recash_password,
              mobile: this.mobile,
              code: this.cash_code,
            },
            function (res) {
              that.isCash = false;
              that.$utile.prompt("success", "新密码设置成功");
              that.getUser();
            }
        );
      }
      
    }
  },
  watch: {
    "$route.query": {
      handler(newVal, oldVal) {
        console.info(newVal, oldVal);
        this.wxcode = this.$route.query["code"];
        console.log("wxcode", this.wxcode);
        if (this.wxcode) {
          this.wxLoginFun();
        }
      },
      deep: true,
      immediate: true,
    },
    imageUrl() {
      this.thumbFun();
    },
  },
};
</script>

<style scoped>
.userInfo {
  padding-top: 43px;
}

.userInfo_title {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 23px;
}

.userInfo_box {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 61px 95px 0 330px;
  box-sizing: border-box;
  position: relative;
  margin-bottom: 42px;
}

.userInfo_head {
  position: absolute;
  top: 63px;
  left: 83px;
}

.userInfo_head img {
  width: 112px;
  height: 112px;
  border-radius: 112px;
  margin-bottom: 12px;
}

.userInfo_content {
  padding-bottom: 54px;
  border-bottom: 1px solid #eeeeee;
}

.userInfo_content_name {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 32px;
}

.userInfo_item {
  margin-bottom: 26px;
  max-width: 890px;
}

.userInfo_headline {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 14px;
  letter-spacing: 0px;
  color: #999999;
  margin-bottom: 10px;
}

.userInfo_save {
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #5957ff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 6px;
  cursor: pointer;
}

.userInfo_user {
  padding-top: 48px;
  padding-bottom: 50px;
}

.userInfo_user_name {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 18px;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 43px;
}

.userInfo_user_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.userInfo_user_item {
  width: 458px;
  margin-bottom: 36px;
}

.userInfo_user_save {
  margin-top: -5px;
}

.userInfo >>> .upload-demo {
  text-align: center;
}

.userInfo >>> .el-button--primary {
  background-color: #ffffff;
  border-color: #5957ff;
  border-radius: 5px;
  color: #5957ff;
}

.userInfo_user_item_wx {
  position: relative;
}

.userInfo_user_item_bind {
  width: 80px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  border-radius: 8px;
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: #5957ff;
  color: #ffffff;
  cursor: pointer;
}

.more_length /deep/ .el-input__inner {
  border-color: red !important;
}

.userInfo_box /deep/ .el-input__inner {
  background: #f5f5f5;
  color: #979797;
}

.userInfo_box /deep/ .el-input__inner:focus {
  background: #fff;
  color: #333;
}

.userInfo_user_other {
  height: 40px;
  line-height: 40px;
}

.user_info_hang {
  max-width: 890px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.user_info_hang .userInfo_item {
  width: 400px;
}

.userInfo_bind {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  line-height: 40px;
}

.userInfo_bind span {
  color: #979797;
}

.userInfo_bind a {
  color: #5957ff;
  cursor: pointer;
}

.pop-up {
  width: 520px;
  height: 480px;
  position: fixed;
  top: 50%;
  margin-top: -240px;
  left: 50%;
  margin-left: -260px;
  background: #fff;
  border-radius: 10px;
  z-index: 9;
}

.pop-up-bg {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  background: rgba(0, 0, 0, 0.5);
}

.pop-up-head {
  height: 60px;
  border-bottom: 1px #e1e1e1 solid;
  line-height: 60px;
  padding: 0 57px;
}

.pop-up-head h2 {
  float: left;
  height: 59px;
  line-height: 60px;
  font-size: 20px;
  color: #5759fc;
  border-bottom: 2px #5759fc solid;
  padding: 0 5px;
}

.pop-up-head a {
  float: right;
  margin-top: 20px;
  cursor: pointer;
}

.pop-up-head img {
  width: 19px;
  height: 19px;
}

.pop-up-content {
  padding: 0 57px;
  margin-top: 40px;
}

.smsCode_phone {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: solid 1px #eeeeee;
  box-sizing: border-box;
  margin-bottom: 16px;
  position: relative;
}

.smsCode_phone div {
  position: absolute;
  top: 0;
  left: 0;
  width: 67px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}

.smsCode_phone input {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding-left: 67px;
  box-sizing: border-box;
}

.password input {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding-left: 12px;
  box-sizing: border-box;
}

.smsCode_phone input:focus,
.smsCode_code_input input:focus {
  outline: 1px solid #5759fc;
}

.smsCode_code {
  margin-bottom: 17px;
}

.smsCode_code_input {
  width: 272px;
  height: 50px;
  border-radius: 10px;
  border: solid 1px #eeeeee;
  box-sizing: border-box;
}

.smsCode_code_input input {
  width: 100%;
  padding: 0 12px;
  line-height: 48px;
  box-sizing: border-box;
  border-radius: 10px;
}

.smsCode_code_b {
  width: 115px;
  height: 48px;
  line-height: 50px;
  background-color: #f6f6f6;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #696969;
  cursor: pointer;
  border: solid 1px #eeeeee;
}

.btn {
  text-align: center;
  margin-top: 35px;
}

.btn button {
  border: none;
}

div.tips {
  font-size: 12px;
  color: #ccc;
  text-align: center;
  margin-top: 10px;
}

span.tips {
  font-size: 12px;
  color: #ccc;
  text-align: center;
  margin-left: 10px;
}

.logout {
  display: block;
  margin: 15px auto;
  text-align: center;
  cursor: pointer;
  color: rgb(176, 204, 255);
  font-size: 12px;
  text-decoration: underline;
}

.background {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}

.alert {
  width: 255px;
  height: 99px;
  background-color: #ffffff;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -174px;
  margin-top: -75px;
  z-index: 1002;
  padding: 36px 46px;
  letter-spacing: 0.5px;
}

.alert > div:first-child {
  font-weight: bold;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
}

.alert .alert_btn {
  text-align: center;
  line-height: 39px;
  margin-top: 29px;
}

.alert .alert_btn div {
  width: 114px;
  height: 39px;
  border-radius: 20px;
  font-size: 18px;
  display: inline-block;
  cursor: pointer;
}

.alert .alert_btn .alert_btn_cencel {
  background-color: #e5e5e5;
  color: #333;
  float: left;
}

.alert .alert_btn .alert_btn_sure {
  background-color: #5857ff;
  color: #fff;
  float: right;
}
.userInfo_handup {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #5857ff;
  background: #fff;
  color: #5857ff;
  margin-left: 10px;
}

.bg {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.6;
    z-index: 90;
}
.cash_wrap {
    background-color: #fff;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 91;
    width: 410px;
    height: auto;
}
.cashs {
    position: relative;
}

.cash_title {
    width: 100%;
    height: 66px;
    border-bottom: 2px solid #ececec;
    box-sizing: border-box;
    font-size: 20px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cash_box {
    padding: 0 38px 54px;
}

.cash_box_title {
    margin-top: 40px;
    font-size: 18px;
    line-height: 1;
}

.cash_box_input,
.cashing_box_input {
    width: 100%;
    height: 35px;
    padding: 0 18px;
    border: 1px solid #e5e5e5;
    border-radius: 6px;
    box-sizing: border-box;
    margin-top: 22px;
}

.cash_box_input::-ms-input-placeholder {
    color: #a1a1a1;
}

.cash_box_input::-webkit-input-placeholder {
    color: #a1a1a1;
}
.cashs_code{
  position: relative;
}
.cashs_code_b{
  width: 100px;
  height: 35px;
  background-color: #eee;
  border-radius: 4px;
  position: absolute;
  top: 22px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #666;
}

.cash_password {
    margin-top: 160px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 6px;
    background-color: #5859ff;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}
.close {
    position: absolute;
    top: 0px;
    right: -40px;
    width: 32px;
    height: 32px;
    cursor: pointer;
}

.close img {
    width: 32px;
    height: 32px;
}
</style>
